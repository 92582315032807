
import { defineComponent, ref, watch, onMounted, computed } from "vue";
import { number, object, string } from "yup";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import { VueCookieNext } from "vue-cookie-next";
import { ElNotification } from "element-plus";
import { useBus } from "@/bus.ts";
import { DrawerComponent } from "@/assets/ts/components";
import { months } from "@/core/helpers/genericHelper.ts";

interface FormData {
  activities: {
    title: string;
    month: string;
    status: string;
    description: string;
    results: string;
    challenges: string;
  }[];
}

export default defineComponent({
  mixins: [apiEndpoint],
  name: "IpWorkPlanModal",
  props: {
    data: { type: Object },
  },
  setup(props) {
    const { bus } = useBus();

    const formData = ref({
      id: "",
      updated_by: "",
      created_by: VueCookieNext.getCookie("_user_id"),
      ia_partner_id: "",
      title: "",
      // description: "",
      // results: "",
      // challenges: "",
      year: "",
      activities: [] as {
        title: string;
        month: string;
        status: string;
        description: string;
        results: string;
        challenges: string;
      }[],
    });

    const tableRows = ref([
      { title: "", month: "", status: "", description: "", results: "", challenges: "" },
    ]);

    const addRow = () => {
      tableRows.value.push({
        title: "",
        month: "",
        status: "",
        description: "",
        results: "",
        challenges: "",
      });
    };

    const removeRow = (index) => {
      if (tableRows.value.length > 1) {
        tableRows.value.splice(index, 1);
      }
    };

    watch(
      () => props.data,
      (newData) => {
        if (newData) {
          formData.value = {
            ...formData.value,
            ...newData,
          };

          // tableRows.value = [...formData.value.activities];
          if (newData.year) {
            formData.value.year = new Date(newData.year + '-01-01').toString();
          }

          tableRows.value = newData.activities.map(activity => ({
            title: activity.title,
            month: JSON.parse(activity.month), // Parse the month array
            status: activity.status,
            description: activity.description,  // Add description
            results: activity.results,          // Add results
            challenges: activity.challenges     // Add challenges
          }));
        }
      }
    );

    const schema = object().shape({
      // component_id: number().required("Component is required"),
      // output_id: number().required("Output is required"),
      // activity_no: number().required("Activity line is required"),
      // title: string().required("Activity title is required"),
    });

    const errors = ref({});
    const componentOptions = ref([]);
    const iaPartnerOptions = ref([]);

    const getComponent = async () => {
      try {
        const response = await ApiService.get(
          apiEndpoint.data().VUE_APP_COMPONENT_LIST
        );

        componentOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.name,
        }));
      } catch (error) {
        console.error("Error fetching component options:", error);
      }
    };

    const getIaPartnerOptions = async () => {
      try {

        const id = VueCookieNext.getCookie("_ia_partner_id") !== "null"
          ? VueCookieNext.getCookie("_ia_partner_id")
          : 0;

        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_IAPARTNER_LIST,
          {
            id: id
          }
        );

        iaPartnerOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.name,
        }));

      } catch (error) {
        console.error("Error fetching parent output options:", error);
      }
    };

    const save = async () => {
      try {
        if (formData.value.id) {
          formData.value.updated_by = VueCookieNext.getCookie("_user_id");
        }

        // await schema.validate(formData.value, { abortEarly: false });
        formData.value.activities = tableRows.value;
        ApiService.post(
          apiEndpoint.data().VUE_APP_IPWORKPLAN_STORE,
          formData.value
        )
          .then((response) => {
            console.log(response.data);
            if (response.data.status == "success") {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.data,
                type: "success",
              });
              DrawerComponent.hideAll();
              bus.emit("loadData", true);
            } else {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.msg,
                type: "error",
              });
            }
          })
          .catch(({ response }) => {
            console.log(response);
          });

        formData.value = {
          id: "",
          updated_by: "",
          created_by: VueCookieNext.getCookie("_user_id"),
          title: "",
          // description: "",
          // results: "",
          // challenges: "",
          year: "",
          ia_partner_id: "",
          activities: [] as {
            title: string;
            month: string;
            status: string;
            description: string;
            results: string;
            challenges: string;
          }[],
        };
        errors.value = {};
      } catch (validationErrors) {
        errors.value = validationErrors.inner.reduce((acc, error) => {
          acc[error.path] = error.message;
          return acc;
        }, {});
      }
    };

    onMounted(getIaPartnerOptions);

    return {
      formData,
      iaPartnerOptions,
      errors,
      save,
      tableRows,
      addRow,
      removeRow,
      months,
    };
  },
});
